class Validators {
  verifica_cpf_cnpj(valor: any) {

    // Garante que o valor é uma string
    valor = valor.toString();

    // Remove caracteres inválidos do valor
    valor = valor.replace(/[^0-9]/g, '');

    // Verifica CPF
    if (valor.length === 11) {
      return 'CPF';
    }

    // Verifica CNPJ
    else if (valor.length === 14) {
      return 'CNPJ';
    }

    // Não retorna nada
    else {
      return false;
    }

  }
  calc_digitos_posicoes(digitos: any, posicoes = 10, soma_digitos = 0) {

    // Garante que o valor é uma string
    digitos = digitos.toString();

    // Faz a soma dos dígitos com a posição
    // Ex. para 10 posições:
    //   0    2    5    4    6    2    8    8   4
    // x10   x9   x8   x7   x6   x5   x4   x3  x2
    //   0 + 18 + 40 + 28 + 36 + 10 + 32 + 24 + 8 = 196
    for (let i = 0; i < digitos.length; i++) {
      // Preenche a soma com o dígito vezes a posição
      soma_digitos = soma_digitos + (digitos[i] * posicoes);

      // Subtrai 1 da posição
      posicoes--;

      // Parte específica para CNPJ
      // Ex.: 5-4-3-2-9-8-7-6-5-4-3-2
      if (posicoes < 2) {
        // Retorno a posição para 9
        posicoes = 9;
      }
    }

    // Captura o resto da divisão entre soma_digitos dividido por 11
    // Ex.: 196 % 11 = 9
    soma_digitos = soma_digitos % 11;

    // Verifica se soma_digitos é menor que 2
    if (soma_digitos < 2) {
      // soma_digitos agora será zero
      soma_digitos = 0;
    } else {
      // Se for maior que 2, o resultado é 11 menos soma_digitos
      // Ex.: 11 - 9 = 2
      // Nosso dígito procurado é 2
      soma_digitos = 11 - soma_digitos;
    }

    // Concatena mais um dígito aos primeiro nove dígitos
    // Ex.: 025462884 + 2 = 0254628842
    const cpf = digitos + soma_digitos;

    // Retorna
    return cpf;

  }
  valida_cpf(valor: any) {

    // Garante que o valor é uma string
    // valor = valor.toString();

    // Remove caracteres inválidos do valor
    valor = valor.replace(/[^0-9]/g, '');


    // Captura os 9 primeiros dígitos do CPF
    // Ex.: 02546288423 = 025462884
    const digitos = valor.substr(0, 9);

    // Faz o cálculo dos 9 primeiros dígitos do CPF para obter o primeiro dígito
    let novo_cpf = this.calc_digitos_posicoes(digitos);

    // Faz o cálculo dos 10 dígitos do CPF para obter o último dígito
    novo_cpf = this.calc_digitos_posicoes(novo_cpf, 11);

    // Verifica se o novo CPF gerado é idêntico ao CPF enviado
    if (novo_cpf === valor) {
      // CPF válido
      return true;
    } else {
      // CPF inválido
      return false;
    }

  }
  valida_cnpj(valor: any) {

    // Garante que o valor é uma string
    valor = valor.toString();

    // Remove caracteres inválidos do valor
    valor = valor.replace(/[^0-9]/g, '');


    // O valor original
    const cnpj_original = valor;

    // Captura os primeiros 12 números do CNPJ
    const primeiros_numeros_cnpj = valor.substr(0, 12);

    // Faz o primeiro cálculo
    const primeiro_calculo = this.calc_digitos_posicoes(primeiros_numeros_cnpj, 5);

    // O segundo cálculo é a mesma coisa do primeiro, porém, começa na posição 6
    const segundo_calculo = this.calc_digitos_posicoes(primeiro_calculo, 6);

    // Concatena o segundo dígito ao CNPJ
    const cnpj = segundo_calculo;

    // Verifica se o CNPJ gerado é idêntico ao enviado
    if (cnpj === cnpj_original) {
      return true;
    }

    // Retorna falso por padrão
    return false;

  }
  valida_cpf_cnpj(valor: any) {

    // Verifica se é CPF ou CNPJ
    const valida = this.verifica_cpf_cnpj(valor);

    // Garante que o valor é uma string
    valor = valor.toString();

    // Remove caracteres inválidos do valor
    valor = valor.replace(/[^0-9]/g, '');


    // Valida CPF
    if (valida === 'CPF') {
      // Retorna true para cpf válido
      return this.valida_cpf(valor);
    }

    // Valida CNPJ
    else if (valida === 'CNPJ') {
      // Retorna true para CNPJ válido
      return this.valida_cnpj(valor);
    }

    // Não retorna nada
    else {
      return false;
    }

  }
  formata_cpf_cnpj(valor: any) {

    // O valor formatado
    let formatado = '';

    // Verifica se é CPF ou CNPJ
    const valida = this.verifica_cpf_cnpj(valor);

    // Garante que o valor é uma string
    valor = valor.toString();

    // Remove caracteres inválidos do valor
    valor = valor.replace(/[^0-9]/g, '');


    // Valida CPF
    if (valida === 'CPF') {

      // Verifica se o CPF é válido
      if (this.valida_cpf(valor)) {

        // Formata o CPF ###.###.###-##
        formatado = valor.substr(0, 3) + '.';
        formatado += valor.substr(3, 3) + '.';
        formatado += valor.substr(6, 3) + '-';
        formatado += valor.substr(9, 2) + '';

      }

    }

    // Valida CNPJ
    else if (valida === 'CNPJ') {

      // Verifica se o CNPJ é válido
      if (this.valida_cnpj(valor)) {

        // Formata o CNPJ ##.###.###/####-##
        formatado = valor.substr(0, 2) + '.';
        formatado += valor.substr(2, 3) + '.';
        formatado += valor.substr(5, 3) + '/';
        formatado += valor.substr(8, 4) + '-';
        formatado += valor.substr(12, 14) + '';

      }

    }

    // Retorna o valor
    return formatado;

  }

  formataCpfCnpj(texto: any) {
    let t1 = this.removeFormatacao(texto);
    if (!t1) {
      return null
    } else {
      if (t1.length > 14)
        t1 = t1.substring(0, 14);
      if (t1.length <= 11) {
        if (t1.length < 4)
          return texto;
        if (t1.length <= 6)
          return t1.substr(0, 3) + '.' + t1.substr(3, t1.length);
        if (t1.length <= 9)
          return t1.substr(0, 3) + '.' + t1.substr(3, 3) + '.' + t1.substr(6, t1.length);
        else
          return t1.substr(0, 3) + '.' + t1.substr(3, 3) + '.' + t1.substr(6, 3) + '-' + t1.substr(9, t1.length - 9);
      }
      else if (t1.length === 14) {
        return t1.substr(0, 2) + '.' + t1.substr(2, 3) + '.' + t1.substr(5, 3) + '/' + t1.substr(8, 4) + '-' + t1.substr(12, t1.length - 12);
      }
      else return texto;
    }
  }

  formataNumeroTelefone(numero: any) {
    numero = this.removeFormatacao(numero);
    if (numero) {
      if (numero.length === 0)
        return '';
      if (numero.length < 3)
        return numero;
      if (numero.length < 7)
        return '(' + numero.substring(0, 2) + ') ' + numero.substring(2, numero.length);
      else if (numero.length < 11)
        return '(' + numero.substring(0, 2) + ') ' + numero.substring(2, 6) + '-' + numero.substring(6, numero.length);
      else
        return '(' + numero.substring(0, 2) + ') ' + numero.substring(2, 7) + '-' + numero.substring(7, numero.length);
    }
  }

  removeFormatacao(texto: any) {
    if (!texto) {
      return null;
    } else if (texto.length > 0) {
      return texto.replace(/[^\d]+/g, '');
    }
  }

  formataCep(numero: any) {
    const num = this.removeFormatacao(numero);
    if (num.length < 6)
      return num;
    else
      return num.substring(0, 5) + '-' + num.substring(5, 8);
  }

  formatarCampoArmazenamento(value: number) {
    if (value > 1048576) {
      return (value / 1024 / 1024).toFixed(2) + "mb"
    } else {
      return value > 0 ? (value / 1024).toFixed(2) + "kb" : "0kb";
    }
  }

  formataValorTotal(value: number) {
    if (value && value === 0) {
      return 'R$ 0,00'
    } else {
      return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    }
  }

  formataTipo(value: number) {
    return value === 0 ? 'Entrada' : 'Saída'
  }

  formataFinalidade(value: number) {
    if (value) {
      switch (value) {
        case 1:
          return 'Normal';
          break;
        case 2:
          return 'Complementar';
          break;
        case 3:
          return 'Ajuste';
          break;
        case 4:
          return 'Devolução';
          break;
      }
    }
  }

  formataFinalidadeCte(value: number) {
    switch (value) {
      case 0:
        return 'Normal';
        break;
      case 1:
        return 'Complementar';
        break;
      case 2:
        return 'Anulação';
        break;
      case 3:
        return 'Substituto';
        break;

      default:
        break;
    }

  }

  rangeAno(start: number, stop: number, step: number) {
    return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
  }

}

export default new Validators();